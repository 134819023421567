// import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from 'react';
// import { useForm } from "react-hook-form";
//
// import useCart from "../../context/cart.context";
// import { CHECKOUT } from "../../mutations";
import InputBlock from '../InputBlock';

import 'react-datepicker/dist/react-datepicker.css';
// import { getZipCode } from "../../utils";
// import { TCartItem } from "../../types/types";
// import { useCheckZip } from "../../hooks";
import { RadioGroup } from '@headlessui/react';
import { useUserData } from '../../context/user-data.context';
import { useController } from 'react-hook-form';
import useCart from '../../context/cart.context';
// import ZipcodeSearch from "@stefanzweifel/js-swiss-cantons/src/ZipcodeSearch";

export type CheckoutInputType = {
  shippingAddressEqualsBillingAddress: boolean;
  billingAddress: {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    streetName: string;
    houseNumber: string;
    zipCode: string;
    city: string;
  };
  additional_information: string;
  date: any;
  time: any;
  shippingAddress: {
    firstName: string;
    lastName: string;
    streetName: string;
    houseNumber: string;
    postcode: string;
    city: string;
  };
};

const CheckoutForm = ({
  gender,
  setGender,
  register,
  currentUser,
  setCurrentUser,
  control,
}) => {
  // const [currentUser, setCurrentUser] = useState(null);
  const { userData, setUserData } = useUserData();
  const { zipCode: zip, setZipCode: setZip } = useCart();

  const {
    field: { onChange: setFirstName, value: firstName },
  } = useController({
    name: 'billingAddress.firstName',
    control,
  });
  const {
    field: { onChange: setLastName, value: lastName },
  } = useController({
    name: 'billingAddress.lastName',
    control,
  });
  const {
    field: { onChange: setStreetName, value: streetName },
  } = useController({
    name: 'billingAddress.streetName',
    control,
  });
  const {
    field: { onChange: setHouseNumber, value: houseNumber },
  } = useController({
    name: 'billingAddress.houseNumber',
    control,
  });
  const {
    field: { onChange: setPhoneNumber, value: phoneNumber },
  } = useController({
    name: 'billingAddress.phoneNumber',
    control,
  });
  const {
    field: { onChange: setEmailAddress, value: emailAddress },
  } = useController({
    name: 'billingAddress.emailAddress',
    control,
  });
  const {
    field: { onChange: setCity, value: city },
  } = useController({
    name: 'billingAddress.city',
    control,
  });
  const {
    field: { onChange: setZipCode, value: zipCode },
  } = useController({
    name: 'billingAddress.zipCode',
    control,
  });

  useEffect(() => {
    currentUser &&
      (() => {
        setFirstName(currentUser.firstName);
        setLastName(currentUser.lastName);
        setStreetName(currentUser.streetName);
        setHouseNumber(currentUser.houseNumber);
        setPhoneNumber(currentUser.phoneNumber);
        setEmailAddress(currentUser.emailAddress);
        setCity(currentUser.city);
        setGender(currentUser.gender);
      })();
  }, [currentUser]);

  useEffect(() => {
    zip && setZipCode(zip);
  }, []);

  return (
    <div
      // onSubmit={onSubmit}
      className="px-8 pt-16 pb-36 sm:px-6 lg:pb-16 lg:px-0 lg:row-start-1 lg:col-start-1"
    >
      <div className="max-w-lg mx-auto lg:max-w-none">
        <section aria-labelledby="contact-info-heading" className="space-y-6">
          <h2
            id="contact-info-heading"
            className="pb-3 font-serif text-3xl text-center border-b-2 border-black"
          >
            Rechnungsdetails
          </h2>
          <div>
            <RadioGroup value={currentUser} onChange={setCurrentUser}>
              {userData?.map((user) => (
                <RadioGroup.Option value={user}>
                  {({ checked }) => (
                    <div className={`flex items-center ${checked ? "bg-gray-100" : ""}`}>
                      <input
                        checked={checked}
                        className="text-darkGray focus:ring-0 cursor-pointer"
                        type="radio"
                        name="variant"
                      />
                      <RadioGroup.Label as="div" className="flex-1 pl-3 text-sm">
                        <p className="font-medium text-gray-900">{user.firstName} {user.lastName}</p>
                      </RadioGroup.Label>
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
          <fieldset>
            <RadioGroup
              id="group2"
              value={gender}
              onChange={setGender}
              className="text-left flex flex-row gap-x-5 mt-4"
            >
              <RadioGroup.Option value="Frau">
                {({ checked }) => (
                  <div className="flex items-center space-x-2 !cursor-pointer">
                    <input
                      checked={checked || gender === 'Frau'}
                      // required={!checked}
                      className="text-darkGray focus:ring-0 cursor-pointer"
                      type="radio"
                      name="variant2"
                    />
                    <span
                      onClick={() => (checked = !checked)}
                      className={!checked ? 'text-standardGray' : undefined}
                    >
                      Frau
                    </span>
                  </div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="Herr">
                {({ checked }) => (
                  <div className="flex items-center space-x-2 cursor-pointer">
                    <input
                      checked={checked || gender === 'Herr'}
                      // required={!checked}
                      className="text-darkGray focus:ring-0 cursor-pointer"
                      type="radio"
                      name="variant2"
                    />
                    <span
                      onClick={() => (checked = !checked)}
                      className={!checked ? 'text-standardGray' : undefined}
                    >
                      Herr
                    </span>
                  </div>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          </fieldset>
          <div className="">
            <InputBlock
              label="Vorname"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              required
            />
            <InputBlock
              className="pt-[25px]"
              label="Nachname"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              required
            />
          </div>
          <div className="flex space-x-3">
            <InputBlock
              label="Strasse"
              value={streetName}
              onChange={(e) => setStreetName(e.target.value)}
              type="text"
              required
            />
            <InputBlock
              className="!w-36"
              label="Hausnummer"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
              type="text"
              required
            />
          </div>
          <div className="flex space-x-3">
            <div className="flex flex-col">
              <label className="text-standardGray">PLZ</label>
              <input
                value={zipCode}
                className="customInput"
                required
                onChange={(e) => setZipCode(e.target.value)}
                type="text"
              />
            </div>
            <InputBlock
              label="Ort"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              type="text"
              required
            />
          </div>
          <InputBlock
            label="E-Mail-Adresse"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            type="email"
            required
          />
          {/*<InputBlock*/}
          {/*  label="E-Mail-Adresse (Wiederholen)"*/}
          {/*  value={currentUser ? currentUser?.emailAddress : ''}*/}
          {/*  register={{ ...register('billingAddress.emailAddress') }}*/}
          {/*  type="email"*/}
          {/*  required*/}
          {/*/>*/}
          <InputBlock
            label="Telefonnummer"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            type="text"
            required
          />
        </section>

        {/*<div className="pt-6 mt-10 border-t border-gray-200 sm:flex sm:items-center sm:justify-between">*/}
        {/*  <button*/}
        {/*    type="submit"*/}
        {/*    className="w-full px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm bg-graniteGray hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-black sm:ml-6 sm:order-last sm:w-auto"*/}
        {/*  >*/}
        {/*    Weiter*/}
        {/*  </button>*/}
        {/*  <p className="mt-4 text-sm text-center text-gray-500 sm:mt-0 sm:text-left">*/}
        {/*    Im nächsten Schritt gelangen Sie zur Zahlungsmaske.*/}
        {/*  </p>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default CheckoutForm;
