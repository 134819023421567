import { Popover, Transition } from "@headlessui/react";
import { ChevronUpIcon, MinusCircleIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect } from "react";
import { formatMoney } from "../../utils";
import useCart from "../../context/cart.context";
import { useMutation } from "@apollo/client";
import { VALIDATE_VOUCHER } from "../../mutations";
import CheckIcon from "../../images/vector.svg";
import { navigate } from "gatsby";
import { log } from "util";

const Summary = ({ transactionFailed, cart }) => {
  const filteredProducts = cart.products
    ?.filter((p) => p.product.node.__typename !== "SimpleProduct")
    .filter((p) => p.product.node.name !== "Dummy");
  const simpleProducts = cart.products?.filter(
    (p) => p.product.node.__typename === "SimpleProduct"
  );

  const { setVoucherCode, removeVoucherCode, refetch, setLastVoucherCode } =
    useCart();
  const [voucherCodeState, setVoucherCodeState] = React.useState<string>("");
  const [voucherCodeDiscount, setVoucherCodeDiscount] =
    React.useState<string>("");
  const [voucherCodeError, setVoucherCodeError] = React.useState<string>("");
  const [checkVoucherCode] = useMutation(VALIDATE_VOUCHER);

  const productShippingData = (product) => {
    const productShippingData = product.extraData.find(
      ({ key }) => key === "ShippingData"
    );
    return productShippingData?.value && JSON.parse(productShippingData?.value);
  };
  // get only numbers from string
  const getNumbers = (str) => {
    return String(String(str).match(/\d+/g));
  };

  console.log("voucherCodeError", voucherCodeError);

  return (
    <section
      aria-labelledby="summary-heading"
      className="lg:row-start-1 lg:col-start-2"
    >
      <div className="max-w-lg mx-auto lg:max-w-none bg-[#f2f2f2] px-8 pt-16 pb-10 sm:px-6 lg:px-10 lg:pb-16">
        <h2
          id="summary-heading"
          className="pb-3 font-serif text-3xl text-center border-b-2 border-black"
        >
          Zusammenfassung
        </h2>

        {transactionFailed && (
          <div className="p-4 bg-red-300">Zahlung fehlgeschlagen.</div>
        )}

        <>
          <ul className="text-sm font-medium text-gray-900 divide-y divide-gray-200">
            {filteredProducts.map((product, productIdx) => {
              let streetNameField, houseNumberField, zipCodeField, cityField;
              try {
                let { streetName, city, zipCode, houseNumber } =
                  productShippingData(product);
                streetNameField = streetName;
                cityField = city;
                zipCodeField = zipCode;
                houseNumberField = houseNumber;
              } catch {
                streetNameField = "";
                cityField = "";
                zipCodeField = "";
                houseNumberField = "";
              }
              const shippingArr = [
                streetNameField,
                houseNumberField,
                zipCodeField,
                cityField,
              ];
              const bundledProducts = simpleProducts.filter((additional) => {
                const bundledBy = additional.extraData.find(
                  ({ key }) => key === "bundled_by"
                );
                return bundledBy?.value === product.key;
              });
              console.log("bundledProducts", product, bundledProducts);
              const productIsCoupon = product.extraData.find(
                ({ key }) => key === "isCoupon"
              );
              const productDeliveryPrice = product.extraData.find(
                ({ key }) => key === "bbDeliveryPrice"
              )?.value;

              const voucherDeliveryMeta = product.extraData.find(
                ({ key }) => key === "voucherDeliveryMeta"
              )?.value;

              const productDeliveryByPost =
                productIsCoupon &&
                voucherDeliveryMeta &&
                voucherDeliveryMeta !== "per E-Mail an mich senden";
              // get voucherCodeMeta from product
              // const voucherCodeMeta = product.extraData.find(
              //   ({ key }) => key === "voucherCode"
              // );

              console.log("bundledProductssss", bundledProducts);
              return (
                <li
                  key={productIdx}
                  className="flex items-center justify-between sm:pb-2 sm:pt-6 !border-b-2 !border-b-black"
                >
                  <div className="flex flex-col w-full">
                    <div className="flex justify-between items-center w-full pb-2">
                      <div className="">
                        <h3 className="text-sm">
                          <a
                            href={product.href}
                            className="font-bold text-gray-800 hover:text-gray-900"
                          >
                            {product.quantity > 1 ? product.quantity : ""}{" "}
                            {product.quantity > 1 && "x"}{" "}
                            {product.extraData.find(
                              (item) => item.key === "isCoupon"
                            )
                              ? ""
                              : ""}{" "}
                            {product.product.node.name}
                          </a>
                        </h3>
                      </div>
                      <div className="">
                        <div className="flex text-sm">
                          <p className="text-gray-500">{product.color}</p>
                          {product.size ? (
                            <p className="pl-4 ml-4 text-gray-500 border-l border-gray-200">
                              {product.size}
                            </p>
                          ) : null}
                        </div>
                        <p className="text-sm font-bold text-black">
                          {formatMoney(product.product.node.price)}
                        </p>
                      </div>
                    </div>
                    {/*{TODO: change bundled products design}*/}
                    {bundledProducts.map(
                      (bundledProduct, bundledProductIdx) => {
                        return (
                          <div
                            className="flex justify-between w-full pb-2"
                            key={bundledProductIdx}
                          >
                            <div className="">
                              <h4 className="text-sm font-normal">
                                {bundledProduct.quantity}x{" "}
                                {bundledProduct.product.node.name}
                              </h4>
                            </div>
                            <div className="">
                              <div className="flex text-sm">
                                <p className="text-gray-500">{product.color}</p>
                                {product.size ? (
                                  <p className="pl-4 ml-4 text-gray-500 border-l border-gray-200">
                                    {product.size}
                                  </p>
                                ) : null}
                              </div>
                              <p className="text-sm font-medium text-gray-900">
                                {formatMoney(bundledProduct.total)}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                    <div className="flex mt-1 text-sm w-full">
                      <p className="text-sm font-normal">{product.color}</p>
                      {product.size ? (
                        <p className="pl-4 ml-4 text-sm font-medium text-gray-90">
                          {product.size}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      {productDeliveryByPost && (
                        <div className="flex my-1 text-sm w-full justify-between">
                          <p className="text-sm font-normal">Postversand</p>
                          <p
                            className={`pl-4 ml-4 text-sm font-medium text-gray-90`}
                          >
                            {formatMoney(5)}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between w-full pb-2">
                      <div className="">
                        <h3 className="text-sm font-normal">
                          Lieferkosten nach {zipCodeField}
                        </h3>
                      </div>
                      <div className="">
                        <div className="flex text-sm">
                          <p className="text-gray-500">{product.color}</p>
                          {product.size ? (
                            <p className="pl-4 ml-4 text-gray-500 border-l border-gray-200">
                              {product.size}
                            </p>
                          ) : null}
                        </div>
                        <p className="text-sm font-medium text-gray-900">
                          {formatMoney(
                            Number(productDeliveryPrice?.replace(",", "."))
                          ) || formatMoney(0)}
                        </p>
                      </div>
                    </div>
                    <h3 className="text-sm font-normal pb-2">
                      Lieferadresse Brunch: {shippingArr.join(" ")}
                    </h3>
                  </div>

                  <label htmlFor={`quantity-${productIdx}`} className="sr-only">
                    Quantity, {product.name}
                  </label>
                </li>
              );
            })}
          </ul>
        </>

        <dl className="space-y-4 pt-6 text-sm font-medium text-gray-900 border-t border-gray-200">
          {formatMoney(cart.shippingTotal) !== "CHF 0.00" && (
            <div className="flex items-center justify-between">
              <dt className="text-gray-600">Postversand</dt>
              <dd>{formatMoney(cart.shippingTotal)}</dd>
            </div>
          )}
          <div className="flex items-center justify-between">
            <dt className="text-gray-600">Zwischensumme</dt>
            <dd>{formatMoney(cart.total)}</dd>
          </div>
          <div className="items-center w-[55%]">
            <label htmlFor="voucherCode" className="text-[#a9a7a7]">
              Code
            </label>
            <div className="flex items-center justify-between">
              <div className="w-full flex justify-between relative">
                <input
                  type="text"
                  onChange={(event) => setVoucherCodeState(event.target.value)}
                  value={voucherCodeState}
                  className="customInput"
                />
                {voucherCodeState && (
                  <button
                    onClick={() =>
                      removeVoucherCode(
                        cart.appliedCoupons.map((item) => {
                          return item.code;
                        })
                      ).then(() => {
                        refetch();
                        setVoucherCodeState("");
                        setVoucherCodeDiscount(null);
                      })
                    }
                    className="h-5 w-5 rounded-full border-[1px] border-[#d2d1d1] text-[#d2d1d1] absolute top-[25%] right-[70px]"
                  >
                    ✕
                  </button>
                )}

                <button
                  type="button"
                  onClick={async () => {
                    if (!cart?.appliedCoupons?.length) {
                      const result = await checkVoucherCode({
                        variables: { code: voucherCodeState },
                      }).catch((res) => {
                        console.log(res.message);
                        !(
                          res?.message ===
                          "Cannot use voucher code with a voucher product inside the card."
                        )
                          ? setVoucherCodeError(
                              `Gutscheincode "${voucherCodeState}" ungültig`
                            )
                          : setVoucherCodeError(
                              `Gutscheincode "${voucherCodeState}" für Gutscheinkauf nicht anwendbar`
                            );
                      });
                      // @ts-ignore
                      if (result?.data?.validateVoucher?.valid) {
                        setVoucherCodeError("");
                        setVoucherCodeDiscount(
                          getNumbers(
                            result.data.validateVoucher.remaining_value
                          )
                        );
                        setLastVoucherCode(voucherCodeState);
                        setVoucherCodeState("");
                        setVoucherCode("");
                        refetch();
                      }
                    } else {
                      setVoucherCodeError(
                        "Sie haben bereits einen Gutscheincode eingelöst"
                      );
                    }
                  }}
                >
                  <div className="hover: px-3 py-2 ml-5 bg-[#cccccc] hover:bg-oliveStrong">
                    <CheckIcon src="./vector.svg" alt="" />
                  </div>
                </button>
              </div>
            </div>
            <div className="pt-4">
              <p className="text-red-800 text-xs">
                {voucherCodeError && voucherCodeError}
              </p>
            </div>
          </div>
          {cart.appliedCoupons?.length > 0 && (
            <div className="flex items-center justify-between pt-4 border-t-2 border-black">
              <dt className="flex text-sm text-gray-600">
                <span>abzüglich Gutschein</span>
                <a
                  href="/"
                  className="flex-shrink-0 ml-2 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">
                    Erfahren Sie, wie sich die Steuern zusammensetzen.
                  </span>
                </a>
              </dt>
              <dd className="text-sm font-medium text-gray-900">
                {cart.appliedCoupons?.map((cou, index) => (
                  <div className="flex" key={index}>
                    {cou.code.toUpperCase()}{" "}
                    <MinusCircleIcon
                      className="w-5 h-5 ml-2"
                      onClick={() => removeVoucherCode(cou.code)}
                    />
                  </div>
                ))}
              </dd>
            </div>
          )}
          <div className="flex items-center justify-between pt-6 border-t-2 border-black ">
            <dt className="text-base">Total</dt>
            <dd className="text-base">{formatMoney(cart.total)}</dd>
          </div>
        </dl>

        <div className="flex flex-col bg-[#f2f2f2] p-3 my-6 space-y-3">
          <button
            type="submit"
            className="w-full px-4 py-3 text-sm font-medium text-white border border-transparent shadow-sm bg-olive hover:bg-oliveStrong focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-black sm:order-last sm:w-auto"
          >
            WEITER
          </button>
          <button
            onClick={() => navigate("/cart")}
            className="w-full px-4 py-3 text-sm font-medium text-white border border-transparent shadow-sm bg-graniteGray hover:bg-black/80  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-black sm:order-last sm:w-auto"
          >
            ZURÜCK
          </button>
        </div>

        <Popover className="fixed inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden">
          <div className="relative z-10 px-4 bg-white border-t border-gray-200 sm:px-6">
            <div className="max-w-lg mx-auto">
              <Popover.Button className="flex items-center w-full py-6 font-medium">
                <span className="mr-auto text-base">Total</span>
                <span className="mr-2 text-base">
                  {formatMoney(cart.total)}
                </span>
                <ChevronUpIcon
                  className="w-5 h-5 text-gray-500"
                  aria-hidden="true"
                />
              </Popover.Button>
            </div>
          </div>

          <Transition.Root as={Fragment}>
            <div>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-y-full"
                enterTo="translate-y-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-full"
              >
                <Popover.Panel className="relative px-4 py-6 bg-white sm:px-6">
                  <dl className="max-w-lg mx-auto space-y-6">
                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Zwischensumme</dt>
                      <dd>{formatMoney(cart.subtotal)}</dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">Lieferkosten nach</dt>
                      <dd>{formatMoney(cart.shippingTotal)}</dd>
                    </div>

                    <div className="flex items-center justify-between">
                      <dt className="text-gray-600">MWST</dt>
                      <dd>{formatMoney(cart.totalTax)}</dd>
                    </div>
                  </dl>
                </Popover.Panel>
              </Transition.Child>
            </div>
          </Transition.Root>
        </Popover>
      </div>
    </section>
  );
};

export default Summary;
