import React, { useEffect, useState } from "react";
import Title from "../components/title";
import Container from "../components/container";
import { useCart } from "../context/cart.context";
import Summary from "../components/checkout/Summary";
import CheckoutForm, {
  CheckoutInputType,
} from "../components/checkout/CheckoutForm";
import { useMutation } from "@apollo/client";
import { CHECKOUT } from "../mutations";
import { useCheckZip } from "../hooks";
import { TCartItem } from "../types/types";
import { useForm } from "react-hook-form";
import ZipcodeSearch from "@stefanzweifel/js-swiss-cantons/src/ZipcodeSearch";
import { useUserData } from "../context/user-data.context";

const CheckoutPage: React.FunctionComponent = () => {
  const { cart, emptyCart, setZipCode } = useCart();
  const { userData, setUserData, clearUserData } = useUserData();

  const isBrowser = typeof window !== "undefined";

  const transactionFailed =
    isBrowser && window.location.href.includes("payrexx_error");
  const transactionSuccessful =
    isBrowser && window.location.href.includes("order-received");

  const zipCode = isBrowser
    ? (window as Window)?.localStorage.getItem("brunchbutler_zipCode")
    : "";

  const [checkoutMulti] = useMutation(CHECKOUT);
  const checkZip = useCheckZip();
  const [gender, setGender] = useState("");
  const shippingArray = [];
  const cantonChecker = new ZipcodeSearch();
  cart.products.forEach((item: TCartItem) => {
    const shippingData = item.extraData.find((i) => i.key === "ShippingData");
    if (shippingData) {
      console.log("shipp", shippingData);
      const parsedObj = shippingData?.value && JSON.parse(shippingData.value);
      const locationData = cantonChecker.findbyZipcode(parsedObj?.zipCode);
      const requestObj = {
        address1: parsedObj?.streetName + " " + parsedObj?.houseNumber,
        firstName: parsedObj?.firstName,
        lastName: parsedObj?.lastName,
        postcode: parsedObj?.zipCode,
        company: "",
        address2: "",
        country: "CH",
        state: locationData && locationData.canton ? locationData.canton : "",
      };
      requestObj && shippingArray.push(requestObj);
    }
  });


  const initialForm = {
    defaultValues: {
      firstName: "",
      lastName: "",
      additional_information: "",
      zipCode: "",
      date: "",
      time: "",
    },
  };
  const { register, handleSubmit, resetField, getValues, control } =
    useForm<CheckoutInputType>(initialForm);
  const [currentUser, setCurrentUser] = useState(null);
  const onSubmit = (data: CheckoutInputType) => {
    const zipStore = new ZipcodeSearch();
    const locationData = zipStore.findbyZipcode(data.billingAddress.zipCode);
    checkoutMulti({
      fetchPolicy: "no-cache",
      variables: {
        billingFirstName: data.billingAddress.firstName,
        billingLastName: data.billingAddress.lastName,
        billingAddress:
          data.billingAddress.streetName +
          " " +
          data.billingAddress.houseNumber,
        billingPostCode: data.billingAddress.zipCode,
        billingCity: data.billingAddress.city,
        billingEmail: data.billingAddress.emailAddress,
        billingPhone: data.billingAddress.phoneNumber,
        billingState:
          locationData && locationData.canton ? locationData.canton : "",
        billingCountry: "CH",
        metaData: [{ key: "billing_gender", value: gender }],
        shipping: shippingArray.length > 1 ? [] : [shippingArray[0]],
        customerNote: `${data.additional_information}. Date: ${data.date}. Time: ${data.time}`,
      },
    })
      .then((d) => {
        typeof window !== "undefined"
          ? // @ts-ignore
           (window.location.href = d?.data.checkoutMulti.redirect)
          : console.error("No redirect");
      })
      .then(() => {
        const newUserData = {
          firstName: data.billingAddress.firstName,
          lastName: data.billingAddress.lastName,
          emailAddress: data.billingAddress.emailAddress,
          phoneNumber: data.billingAddress.phoneNumber,
          zipCode: data.billingAddress.zipCode,
          city: data.billingAddress.city,
          streetName: data.billingAddress.streetName,
          houseNumber: data.billingAddress.houseNumber,
          gender: gender,
        };
        const match = userData.find((user) => {
          return (
            user.firstName === newUserData.firstName &&
            user.lastName === newUserData.lastName &&
            user.zipCode === newUserData.zipCode
          );
        });
        // check all fields if they are empty
        const emptyFields = Object.values(newUserData).some((field) => {
          return field === "";
        });
        //@ts-ignore
        !match && !emptyFields && setUserData([...userData, newUserData]);
      })
      .then(() => {
        // if (checkZip(data.billingAddress.zipCode).variant === "negation") return
        // setZipCode(null);
        clearUserData();
        emptyCart();
        return;
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    console.log(cart.products);
  }, [cart])
  // useEffect(() => {
  //   // !zipCode && navigate("/");
  //
  // }, []);

  return (
    <Container noHero>
      <div className="mx-auto mt-24 lg:mt-12 max-w-7xl">
        <Title
          title={transactionSuccessful ? "Bestellung erhalten" : "Kasse"}
          subtitle={""}
          breadcrumb={"Nur noch wenige Schritte"}
        />
        <div className="relative grid grid-cols-1 mx-auto gap-x-16 max-w-7xl lg:px-8 xl:gap-x-20">
          <h1 className="sr-only">Order information</h1>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="lg:grid  lg:grid-cols-2 lg:gap-x-20 flex flex-col-reverse"
          >
            {!transactionSuccessful && (
              <Summary cart={cart} transactionFailed={transactionFailed} />
            )}

            {!transactionSuccessful && (
              <CheckoutForm
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                register={register}
                gender={gender}
                setGender={setGender}
                control={control}
              />
            )}
          </form>
          {transactionSuccessful && (
            <div className="flex flex-col items-center justify-center w-full">
              <p className="px-4 mx-auto pt-[40px] text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
                Vielen Dank!
              </p>
              <p className="px-4 pt-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
                Ihre Bestellung #{" "}
                {isBrowser && window.location.href.split("=")[1]} ist
                abgeschlossen und übermittelt.
              </p>
              <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
                Sie erhalten per E-Mail eine
                Bestätigung.
              </p>
              <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
                Bitte überprüfen Sie die Angaben.
              </p>
              <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
                Bei Fragen dürfen Sie uns gerne kontaktieren.
              </p>
              <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black pt-4 lg:px-0 max-w-7xl">
                Zu Ihren Diensten und mit genussvollen Grüssen
              </p>
              <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
                Ihr BrunchButler
              </p>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
};

export default CheckoutPage;
